import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import {
  useChangePasswordMutation,
  useChangePasswordSendCodeMutation,
  useChangePasswordVerifyCodeMutation,
  useResendVerifyEmailMutation
} from 'services/auth/auth';

import { selectUser } from 'store';
import { setUser } from 'store/user/userSlice';

import ChangePasswordSuccess from 'pages/MyAccount/components/ChangePassword/ChangePasswordSuccess';
import NewPasswordForm from 'pages/MyAccount/components/ChangePassword/NewPasswordForm';
import { NewPasswordFormInput } from 'pages/MyAccount/components/ChangePassword/NewPasswordForm/newPasswordForm.types';
import VerifyCodeForm from 'pages/MyAccount/components/ChangePassword/VerifyCodeForm';
import { EmailVerificationForm } from 'pages/MyAccount/components/ChangePassword/VerifyCodeForm/verifyCodeForm.types';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import { notifyError, notifySuccess } from 'shared/Toast/Toast';

import { useAppDispatch, useAppSelector } from 'hooks';
import { EmailStatus } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const { accessToken, changePassword, email, emailStatus } = useAppSelector(selectUser);
  const [sendCode, { isLoading: isLoadingSendCode }] = useChangePasswordSendCodeMutation();
  const [verifyCode, { isLoading: isLoadingVerifyCode }] = useChangePasswordVerifyCodeMutation();
  const [changePasswordToNew, { isLoading: isLoadingChangePassword }] = useChangePasswordMutation();
  const [resendVerifyEmail, { isLoading: isLoadingResendVerifyEmail }] =
    useResendVerifyEmailMutation();
  const [verificationCode, setVerificationCode] = useState<string | null>(null);
  const [isChangeSuccess, setIsChangeSuccess] = useState(false);

  const isLoading = isLoadingSendCode || isLoadingChangePassword || isLoadingResendVerifyEmail;

  const sendChangePasswordCodeEmail = () =>
    sendCode(accessToken)
      .unwrap()
      .then(() => {
        notifySuccess('Email with verification code sent successfully');
        dispatch(setUser({ changePassword: { sentAt: new Date() } }));
      })
      .catch(handleRequestCatch);

  const resendVerifyLinkEmail = () =>
    resendVerifyEmail({ accessToken })
      .unwrap()
      .then(() => {
        notifySuccess('Email with verification link sent successfully');
        dispatch(setUser({ verifyEmail: { sentAt: new Date() } }));
      })
      .catch(handleRequestCatch);

  const handleResendClick = () => {
    if (emailStatus === EmailStatus.VERIFIED) {
      sendChangePasswordCodeEmail();
    } else {
      resendVerifyLinkEmail();
    }
  };

  const onEmailVerifyCodeFormSubmit = (data: EmailVerificationForm) => {
    verifyCode({ accessToken, code: data.code })
      .unwrap()
      .then(() => {
        setVerificationCode(data.code);
      })
      .catch(() => notifyError('Incorrect verification code. Please try again.'));
  };

  const onPasswordFormSubmit = (data: NewPasswordFormInput) => {
    if (!verificationCode) return;
    changePasswordToNew({ accessToken, code: verificationCode, newPassword: data.newPassword })
      .unwrap()
      .then(() => {
        notifySuccess('Successfully updated the password');
        setIsChangeSuccess(true);
      })
      .catch(handleRequestCatch);
  };

  const onAgainClick = () => {
    setIsChangeSuccess(false);
    setVerificationCode(null);
  };

  const renderStep = () => {
    if (verificationCode && isChangeSuccess) {
      return <ChangePasswordSuccess onAgainClick={onAgainClick} />;
    }
    if (verificationCode && !isChangeSuccess) {
      return <NewPasswordForm onCancel={onAgainClick} onSubmit={onPasswordFormSubmit} />;
    }
    return (
      <VerifyCodeForm
        changePassword={changePassword}
        email={email}
        isLoading={isLoadingVerifyCode}
        onResendClick={handleResendClick}
        onSubmit={onEmailVerifyCodeFormSubmit}
      />
    );
  };

  useEffect(() => {
    if (
      !changePassword?.sentAt ||
      (changePassword?.expiresAt &&
        dayjs.utc(changePassword?.expiresAt).diff(dayjs.utc(), 'minutes') <= 0)
    ) {
      sendChangePasswordCodeEmail();
    }
  }, []);

  return (
    <FadeWrapper>
      <Loader isVisible={isLoading} />
      {renderStep()}
    </FadeWrapper>
  );
};

export default ChangePassword;
