import { Common } from '@thecvlb/design-system/lib/src';

import { PossibleMifCheckResults } from 'widgets/MigrateFromTirzepatide/migrateFormTirzepatide.types';

interface ResultsProps {
  onContinue: () => void;
  status: PossibleMifCheckResults;
}

const Results: React.FC<ResultsProps> = ({ status, onContinue }) => {
  switch (status) {
    case 'not-eligible-care':
    case 'not-eligible-bundled':
    case 'maintenance-bundled':
      return (
        <div className="flex grow flex-col gap-6 max-md:h-full">
          <div className="flex flex-col items-center gap-2 text-center max-md:py-6">
            <Common.Illustration name="success" />
            <h3 className="text-m2xl font-bold md:text-2xl">
              You’re eligible for compounded semaglutide
            </h3>
            <span className="mb-3">
              As we can no longer prescribe compounded tirzepatide due to recent FDA announcements
              and corresponding changes in tirzepatide regulations, we have clinically determined
              that semaglutide is your best option.
            </span>
            <span>
              Semaglutide is commonly prescribed for weight management and is often a more
              affordable choice than tirzepatide.
            </span>
            <button className="font-bold" onClick={() => {}}>
              View FAQs
            </button>
          </div>
          <div className="mt-auto flex flex-col items-center gap-3 max-md:py-6 md:self-center">
            <Common.Button color="blue" fullWidthOnMobile onClick={onContinue}>
              I agree! Continue with semaglutide
            </Common.Button>
            <Common.Button color="white-alt" fullWidthOnMobile>
              Discuss with my provider
            </Common.Button>
          </div>
        </div>
      );
    case 'eligible-tirz-plus':
      return (
        <div className="flex grow flex-col gap-6 max-md:h-full">
          <div className="flex flex-col items-center gap-2 text-center max-md:py-6">
            <Common.Illustration name="success" />
            <h4 className="font-bold text-primary">Great news!</h4>
            <h3 className="text-m2xl font-bold md:text-2xl">
              You’re eligible to continue with a compounded tirzepatide
            </h3>
            <div>
              <span className="mb-1 block text-center font-bold text-primary">What to expect:</span>
              <span>
                No action is required on your part. Your medication will have the same active
                ingredient as your current medication (tirzepatide) plus L-arginine, which based on
                your medical profile, is clinically appropriate.
              </span>
            </div>

            <button className="font-bold" onClick={() => {}}>
              View FAQs
            </button>
          </div>
          <div className="mt-auto flex flex-col items-center gap-3 max-md:py-6 md:self-center">
            <Common.Button color="blue" fullWidthOnMobile onClick={onContinue}>
              Continue
            </Common.Button>
            <Common.Button color="white-alt" fullWidthOnMobile>
              View FAQs
            </Common.Button>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default Results;
