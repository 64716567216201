import { Common } from '@thecvlb/design-system/lib/src';

import { ShippingToProps } from './shippingTo.types';

const ShippingTo: React.FC<ShippingToProps> = ({ address, onClickEdit }) => {
  return (
    <div className="flex items-center justify-between gap-3 rounded-2xl border border-gray-200 p-4">
      <div className="grow">{address}</div>
      {!!onClickEdit && (
        <button className="flex-none rounded-lg bg-gray-100 p-4" onClick={onClickEdit}>
          <Common.Icon name="pencil" />
        </button>
      )}
    </div>
  );
};

export default ShippingTo;
