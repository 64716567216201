import { useTitle, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { useGetShopOrdersQuery, useLazyGetSingleOrderDetailsQuery } from 'services/shop/shop';

import ShopOrderDetails from 'modals/ShopOrderDetails';
import Loader from 'shared/Loader';

import { LINK_TO_SUPPORT } from 'constants/externalLinks';
import { handleRequestCatch } from 'utils/helpers';

import OrderItem from './components/OrderItem';

import EmptyOrders from 'assets/icons/search-with-cloud.svg?react';

const OrdersHistory = () => {
  useTitle('LifeMD: Order history');
  const { data, isFetching } = useGetShopOrdersQuery();
  const [getOrderDetails, { data: singleOrderData, isFetching: isGettingOrderDetails }] =
    useLazyGetSingleOrderDetailsQuery();

  const [isOpenDetailsPopup, toggleDetailsPopup] = useToggle(false);

  const ordersList = data?.data ?? [];
  const isOrdersEmpty = ordersList.length === 0;

  const handleClickOnOrder = (orderId: string) => {
    getOrderDetails(orderId)
      .unwrap()
      .then(() => toggleDetailsPopup())
      .catch(handleRequestCatch);
  };

  return (
    <>
      <Loader isVisible={isFetching || isGettingOrderDetails} />
      <ShopOrderDetails
        data={singleOrderData?.data}
        isOpen={isOpenDetailsPopup}
        onClose={toggleDetailsPopup}
      />
      <div className="flex h-full flex-col">
        {isOrdersEmpty && !isFetching ? (
          <div className="mx-auto flex h-full max-w-[306px] flex-col place-content-center items-center gap-2.5 py-8 text-center max-md:grow">
            <EmptyOrders className="size-[52px]" />
            <span className="text-gray">
              No orders made yet. When you make a purchase your orders will appear here.
            </span>
          </div>
        ) : (
          <div className="flex h-full flex-col divide-y-8 divide-gray-100 overflow-auto max-md:-mx-4">
            {ordersList.map((order) => (
              <OrderItem
                key={order._id}
                order={order}
                onClick={() => handleClickOnOrder(order._id)}
              />
            ))}
          </div>
        )}
        <Common.Button
          className="mt-auto md:hidden"
          color="blue"
          fullWidthOnMobile
          onClick={() => window.open(LINK_TO_SUPPORT)}
        >
          Contact Support
        </Common.Button>
      </div>
    </>
  );
};

export default OrdersHistory;
