import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import {
  useAddToCartMutation,
  useGetSingleProductDetailsQuery,
  useLazyGetCartQuery
} from 'services/shop/shop';

import { selectShop } from 'store';
import { setActiveProductName } from 'store/shop/shopSlice';

import AccordionInfo from 'features/AccordionInfo/AccordionInfo';
import Loader from 'shared/Loader';

import { useAppDispatch, useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

const Product = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id = '' } = useParams<{ id: string }>();

  const [getCart] = useLazyGetCartQuery();
  const [addToCart, { isLoading }] = useAddToCartMutation();
  const { data, isFetching } = useGetSingleProductDetailsQuery(id);

  const { cart } = useAppSelector(selectShop);
  const [activeIndex, setActiveIndex] = useState(0);

  const productInfo = data?.data;
  const isItemIncludedToCart = cart.items.some((item) => item.productId === id);
  const isPriceWithDiscount = Number(productInfo?.msrpPrice) > Number(productInfo?.price.unitPrice);

  useTitle(`LifeMD: ${productInfo?.name || 'Product details'}`);

  const handleAddToCart = () => {
    if (isItemIncludedToCart) {
      return navigate(PathName.ShopCart);
    }
    addToCart({ productId: id, count: 1 })
      .unwrap()
      .then(() => getCart())
      .then(() => navigate(PathName.ShopCart))
      .catch(handleRequestCatch);
  };

  const getThumbs = () =>
    productInfo?.assets.map((asset) => (
      <img
        alt={asset.url}
        className="size-10 max-h-[40px] select-none object-contain"
        key={asset.url}
        src={asset.url}
      />
    )) || [];

  useEffect(() => {
    dispatch(setActiveProductName(productInfo?.name ?? ''));
    return () => {
      dispatch(setActiveProductName(''));
    };
  }, [productInfo]);

  return (
    <>
      <Loader isVisible={isFetching} />
      {!!productInfo && (
        <div className="mt-5 flex gap-5 max-md:flex-col md:gap-2.5">
          <div className="w-full max-w-full flex-1 overflow-hidden md:h-[440px]">
            <Carousel
              centerSlidePercentage={100}
              renderThumbs={getThumbs}
              selectedItem={activeIndex}
              showArrows={false}
              showIndicators={false}
              showStatus={false}
              swipeScrollTolerance={20}
              thumbWidth={50}
              centerMode
              emulateTouch
              infiniteLoop
              preventMovementUntilSwipeScrollTolerance
              swipeable
              onChange={setActiveIndex}
            >
              {productInfo.assets.map((image) => (
                <div className="select-none rounded-2xl bg-gray-100 p-10 md:mx-2" key={image.url}>
                  <img
                    alt="img"
                    className="max-h-[300px] object-contain"
                    key={image.url}
                    src={image.url}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div className="flex-1">
            <div className="mb-3 flex items-center justify-between">
              <div className="text-mLg">
                <h3 className="font-semibold text-gray-800 md:font-bold">{productInfo.name}</h3>
                <span className="text-mBase md:text-lg">{productInfo.type}</span>
              </div>
              <div>
                <p className="text-mLg">
                  {isPriceWithDiscount ? (
                    <span>
                      <span className="text-green">
                        <span className="mr-1 text-gray line-through">
                          ${productInfo.msrpPrice}
                        </span>
                        ${productInfo.price.unitPrice}
                      </span>
                    </span>
                  ) : (
                    <span className="text-gray-700">${productInfo.price.unitPrice}</span>
                  )}
                </p>
              </div>
            </div>
            <p>{productInfo.description}</p>
            <div className="mb-4">
              <AccordionInfo items={productInfo.extraDescription} />
            </div>
            <Common.Button
              className="z-20 !w-full max-md:sticky max-md:bottom-4"
              color="blue"
              isLoading={isLoading}
              size="lg"
              fullWidthOnMobile
              onClick={handleAddToCart}
            >
              {isItemIncludedToCart ? 'View in cart' : 'Add to cart'}
            </Common.Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Product;
