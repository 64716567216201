export const MIGRATE_TO_TT_FLOW_STEPS = [
  'why-tt',
  'you-are-in-good-hands',
  'height-and-weight',
  'congrats',
  'what-next',
  'wm-diabetes-question',
  'kidney-stones',
  'prior-seizure',
  'glaucoma',
  'mao-inhibitors',
  'liver-or-kidney-impairment',
  'lactic-acidosis',
  'bupropion-diet',
  'side-effects-with-metformin-bupropion',
  'ketogenic-diet',
  'bipolar-disorder',
  'questioner-results',
  'lab-work',
  'plan-details',
  'schedule-appointment',
  'checkout',
  'complete-migration'
] as const;

export type PossibleStepsType = typeof MIGRATE_TO_TT_FLOW_STEPS;
export type StepName = PossibleStepsType[number];

export type MoveToStepFunction = (v: 'prev' | 'next' | StepName, extraSearch?: string) => void;

export type ContentProps = {
  loading: boolean;
  moveToStep: MoveToStepFunction;
  onSelectInsurance: (type: boolean) => Promise<unknown>;
  step: StepName;
  steps: StepName[];
};
