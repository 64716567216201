import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetSet } from 'react-use';
import { AnimatePresence } from 'framer-motion';

import { useGetMembershipPlansQuery } from 'services/lookup/lookup';
import { useLazyGetMifStructureQuery, useSendMifResponseMutation } from 'services/mifs/mifs';
import { MifStructure } from 'services/mifs/mifs.types';

import { selectMifInfo, selectUser } from 'store';
import { selectLookup } from 'store';
import { setTirzepatideMif } from 'store/mif/mifSlice';

import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import Loader from 'shared/Loader';
import Header from 'widgets/Header';

import {
  SEMAGLUTIDE_MAINTENANCE_PRICE_POINT,
  SEMAGLUTIDE_PRICE_POINT,
  TIRZEPATIDE_MAINTENANCE_PRICE_POINT,
  TIRZEPATIDE_PRICE_POINT
} from 'constants/onboarding';
import { useAppDispatch, useAppSelector } from 'hooks';
import { PlanCodes } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import {
  Intro,
  MifQuestionOne,
  MifQuestionThree,
  MifQuestionTwo,
  Results,
  SelectMedication
} from './components';
import { PossibleMifCheckResults } from './migrateFormTirzepatide.types';

const validSteps = [
  'intro',
  'mif-1',
  'mif-2',
  'mif-3',
  'results',
  'select-medication',
  'appointment-tbd',
  'checkout'
];

const ID_FOR_MIF_REQUESTS = 'tirzepatide-migration';

const MigrateFromTirzepatide = ({
  onComplete,
  onLeaveFlow
}: {
  onComplete: () => void;
  onLeaveFlow: () => void;
}) => {
  const dispatch = useAppDispatch();
  const DUMMY_RESULT: PossibleMifCheckResults = 'maintenance-bundled';
  const { tirzepatideMif } = useAppSelector(selectMifInfo);
  const [params, setParams] = useSearchParams();
  const step = params.get('s') ?? '';
  const [isBackAnimation, setIsBackAnimation] = useGetSet(false);

  const navigate = useNavigate();
  const { membershipPlans } = useAppSelector(selectLookup);
  const { activePricePoint } = useAppSelector(selectUser);

  const [mifSteps, setMifSteps] = useState<MifStructure['configs']>([]);

  const { isFetching, isSuccess } = useGetMembershipPlansQuery();
  const [getMifStructure, { isLoading: isMifLoading }] = useLazyGetMifStructureQuery();
  const [submitMif, { isLoading }] = useSendMifResponseMutation();

  const WMPLan = membershipPlans.find((p) => p.planCode === PlanCodes.WeightManagementMembership);
  const pricePoints = WMPLan?.pricePoints;

  const userCurrentPPType = activePricePoint?.includes('low-dose') ? 'maintenance' : 'bundle';

  const ppToUpgrade = pricePoints?.find(
    (pp) =>
      pp.planPricePointId ===
      (userCurrentPPType === 'bundle'
        ? SEMAGLUTIDE_PRICE_POINT
        : SEMAGLUTIDE_MAINTENANCE_PRICE_POINT)
  );

  const tirzepatidePP = pricePoints?.find(
    (pp) =>
      pp.planPricePointId ===
      (userCurrentPPType === 'bundle'
        ? TIRZEPATIDE_PRICE_POINT
        : TIRZEPATIDE_MAINTENANCE_PRICE_POINT)
  );

  const handleSaveMif = ({ question, answer }: { answer: string[]; question: string }) => {
    dispatch(
      setTirzepatideMif([
        {
          question,
          answer
        }
      ])
    );
    if (question === 'mif-3' && 1 > 2) {
      submitMif({ body: [...tirzepatideMif, { question, answer }], id: ID_FOR_MIF_REQUESTS })
        .unwrap()
        .then(() => {
          handleNavigate('next');
        })
        .catch(handleRequestCatch);
    } else {
      handleNavigate('next');
    }
  };

  const handleNavigate = (type: 'prev' | 'next') => {
    window.scrollTo({ left: 0, top: 0 });
    if (type === 'prev') {
      if (validSteps.indexOf(step) === 0) {
        return onLeaveFlow();
      }
      navigate(-1);
      setIsBackAnimation(true);
    } else {
      setIsBackAnimation(false);
      switch (step) {
        case 'intro':
          return setParams((p) => {
            p.set('s', 'mif-1');
            return p;
          });
        case 'mif-1':
          return setParams((p) => {
            p.set('s', 'mif-2');
            return p;
          });
        case 'mif-2':
          return setParams((p) => {
            p.set('s', 'mif-3');
            return p;
          });
        case 'mif-3':
          return setParams((p) => {
            p.set('s', 'results');
            return p;
          });
        case 'results':
          switch (DUMMY_RESULT as PossibleMifCheckResults) {
            case 'eligible-tirz-plus':
            case 'not-eligible-care':
              return onComplete();
            case 'not-eligible-bundled':
            case 'maintenance-bundled':
              return setParams((p) => {
                p.set('s', 'select-medication');
                return p;
              });
            default:
              return onComplete();
          }
        default:
          return navigate({ search: 's=intro' });
      }
    }
  };

  const handleSelectOption = (v: string) => {
    if (v === 'appointment') {
      setParams((p) => {
        p.set('s', 'appointment-tbd');
        return p;
      });
    } else {
      setParams((p) => {
        p.set('s', 'checkout');
        return p;
      });
    }
  };

  const onInit = () => {
    if (!isSuccess && !membershipPlans.length) return;
    getMifStructure(ID_FOR_MIF_REQUESTS)
      .unwrap()
      .then(({ data }) => {
        setMifSteps(data.configs.toSorted((a, b) => a.order - b.order));
      });
    if (!step || !validSteps.includes(step)) {
      setParams(
        (p) => {
          p.set('s', 'intro');
          return p;
        },
        {
          replace: true
        }
      );
    }
  };

  const getSelectedItems = (question: string) => {
    return tirzepatideMif.find((item) => item.question === question)?.answer ?? [];
  };

  useEffect(onInit, [isSuccess, step]);

  return (
    <div className="flex h-full flex-col gap-4">
      <Header
        flowLength={validSteps.length}
        step={validSteps.indexOf(step)}
        onClickPrev={() => handleNavigate('prev')}
      />
      <AnimatePresence mode="wait">
        {isFetching || isMifLoading ? (
          <Loader isVisible />
        ) : (
          <div className="flex grow flex-col px-px pb-4 max-md:h-full md:mx-auto md:w-[960px]">
            {step === 'intro' && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="intro"
              >
                <Intro onContinue={() => handleNavigate('next')} />
              </SlideAnimateWrapper>
            )}
            {step === 'mif-1' && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="mif-1"
              >
                <MifQuestionOne
                  question={mifSteps[0]}
                  selected={getSelectedItems('mif-1')}
                  onContinue={(v) =>
                    handleSaveMif({
                      answer: v,
                      question: 'mif-1'
                    })
                  }
                />
              </SlideAnimateWrapper>
            )}
            {step === 'mif-2' && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="mif-2"
              >
                <MifQuestionTwo
                  question={mifSteps[1]}
                  selected={getSelectedItems('mif-2')}
                  onContinue={(v) =>
                    handleSaveMif({
                      answer: v,
                      question: 'mif-2'
                    })
                  }
                />
              </SlideAnimateWrapper>
            )}
            {step === 'mif-3' && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="mif-3"
              >
                <MifQuestionThree
                  loading={isLoading}
                  question={mifSteps[2]}
                  selected={getSelectedItems('mif-3')}
                  onContinue={(v) =>
                    handleSaveMif({
                      answer: v,
                      question: 'mif-3'
                    })
                  }
                />
              </SlideAnimateWrapper>
            )}
            {step === 'results' && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="results"
              >
                <Results status={DUMMY_RESULT} onContinue={() => handleNavigate('next')} />
              </SlideAnimateWrapper>
            )}
            {step === 'select-medication' && (
              <SlideAnimateWrapper
                className="h-full md:mx-auto md:max-w-[500px]"
                isBackAnimation={isBackAnimation()}
                key="select-medication"
              >
                <SelectMedication
                  pp={ppToUpgrade}
                  ppType={userCurrentPPType}
                  tirzepatidePP={tirzepatidePP}
                  onContinue={handleSelectOption}
                />
              </SlideAnimateWrapper>
            )}
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MigrateFromTirzepatide;
