import { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { parsePhoneNumber } from 'react-phone-number-input';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';

import { useLazyGetSuggestedAddressesQuery } from 'services/lookup/lookup';
import {
  useAddProfileImageMutation,
  useLazyGetMyAccountQuery,
  useUpdateMyAccountMutation
} from 'services/myAccount/myAccount';

import { selectStates, selectUser } from 'store';

import ProfileAvatar from 'pages/MyAccount/components/AccountInfo/ProfileAvatar';

import SuggestedAddress from 'modals/SuggestedAddress';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Address from 'shared/form/Address';
import BirthInput from 'shared/form/BirthInput';
import GenderSelect from 'shared/form/GenderSelect';
import PhoneInput from 'shared/form/PhoneInput';
import SexAtBirthSelect from 'shared/form/SexAtBirthSelect';
import TextInput from 'shared/form/TextInput';
import VerifyEmailInput from 'shared/form/VerifyEmailInput';
import { notifySuccess } from 'shared/Toast/Toast';

import { TRIPLE_THERAPY_PLAN_NAME } from 'constants/defaults';
import { useAppSelector } from 'hooks';
import useWeightManagement from 'hooks/useWeightManagement';
import { DateFormat, EmailStatus, FeatureFlag } from 'utils/enums';
import {
  buildAddress,
  buildQueryForAddress,
  compareSuggestedAddress,
  formatNumber,
  handleRequestCatch,
  onFormError
} from 'utils/helpers';

import { MailingAddress } from 'models/user.types';

import { AccountFormProps, AccountInfoProps } from './accountInfo.types';

const AccountInfo = () => {
  const isSuggestedAddressEnabled = useFlag(FeatureFlag.SuggestedAddress);
  const { isTTPatient } = useWeightManagement();

  const [isOpenSuggestedAddress, toggleSuggestedAddress] = useToggle(false);
  const [fileForUpdateAvatar, setFileForUpdateAvatar] = useState<File | null>(null);
  const [suggestedAddress, setSuggestedAddress] = useState<MailingAddress | undefined>(undefined);

  const [updateMyAccount, { isLoading: updateMyAccountLoading }] = useUpdateMyAccountMutation();
  const [addProfileImage, { isLoading: addProfileImageLoading }] = useAddProfileImageMutation();
  const [getMyAccount, { data }] = useLazyGetMyAccountQuery();
  const [getSuggestions, { isLoading: isGettingSuggestions }] = useLazyGetSuggestedAddressesQuery();

  const user = useAppSelector(selectUser);
  const states = useAppSelector(selectStates);

  const isLoading = updateMyAccountLoading || addProfileImageLoading;

  const { handleSubmit, formState, control, reset, trigger, setValue, getValues } =
    useForm<AccountFormProps>({
      criteriaMode: 'all',
      mode: 'onChange',
      reValidateMode: 'onChange'
    });

  const { field: AboutMeField } = useController({
    control,
    defaultValue: '',
    name: 'aboutMe',
    rules: {
      required: false
    }
  });

  const handleCancel = () => {
    reset();
    trigger();
    setFileForUpdateAvatar(null);
  };

  const handleUpdateMyAccountThen = () => {
    getMyAccount();
    if (!!suggestedAddress) {
      setSuggestedAddress(undefined);
    }
    notifySuccess('Your profile was successfully updated');
  };

  const handleUserUpdate = (newData: AccountInfoProps) => {
    const parsedPhone = {
      countryCode: parsePhoneNumber(newData.phone)?.country ?? '',
      phoneNumber: parsePhoneNumber(newData.phone)?.nationalNumber ?? ''
    };

    updateMyAccount({
      aboutMe: newData.aboutMe,
      email: newData.email,
      mailingAddress: {
        address: newData.address,
        city: newData.city,
        state: newData.state,
        zipCode: newData.zipCode
      },
      personalInfo: {
        ...newData.personalInfo,
        dob: dayjs(newData.personalInfo.dob).format(DateFormat.YYYY_MM_DD)
      },
      phone: parsedPhone,
      profileImage: newData.profileImage
    })
      .unwrap()
      .then(handleUpdateMyAccountThen)
      .catch(handleRequestCatch);
  };

  const handleUpdateMyAccount = (accountInfo: AccountInfoProps) => {
    toggleSuggestedAddress(false);
    if (fileForUpdateAvatar) {
      const formData = new FormData();
      formData.append('profileImage', fileForUpdateAvatar);
      addProfileImage(formData)
        .unwrap()
        .then((res) => handleUserUpdate({ ...accountInfo, profileImage: res.data.profileImage }))
        .catch(handleRequestCatch);
      return;
    }
    handleUserUpdate({
      ...accountInfo
    });
  };

  const onFormSubmit = (accountInfo: AccountInfoProps) => {
    if (
      (user.address !== accountInfo.address ||
        user.city !== accountInfo.city ||
        user.zipCode !== accountInfo.zipCode ||
        user.state !== accountInfo.state) &&
      isSuggestedAddressEnabled
    ) {
      getSuggestions(buildQueryForAddress(accountInfo, states))
        .unwrap()
        .then(({ data: { suggestions } }) => {
          const isValidAddressIncluded = suggestions.some((address) =>
            compareSuggestedAddress(accountInfo, buildAddress(address, states))
          );
          if (isValidAddressIncluded) {
            handleUpdateMyAccount(accountInfo);
          } else {
            if (suggestions.length > 0) {
              setSuggestedAddress(buildAddress(suggestions[0], states));
            }
            toggleSuggestedAddress();
          }
        })
        .catch(() => handleUpdateMyAccount(accountInfo));
    } else {
      handleUpdateMyAccount(accountInfo);
    }
  };

  const getAddress = () => {
    const formData = getValues();
    return {
      address: formData.address,
      city: formData.city,
      state: formData.state,
      zipCode: formData.zipCode
    };
  };

  const handleUpdatedData = () => {
    if (data) {
      reset({
        aboutMe: data.data.aboutMe,
        address: data.data.address,
        city: data.data.city,
        email: data.data?.pendingEmail ?? data.data.email,
        personalInfo: {
          dob: data.data.dob ? dayjs.utc(data.data.dob).format(DateFormat.MM_DD_YYYY) : '',
          firstName: data.data.firstName,
          gender: data.data.gender,
          lastName: data.data.lastName,
          nickName: data.data.nickName,
          sexAtBirth: data.data.sexAtBirth
        },
        phone: formatNumber(String(data.data?.phone?.phoneNumber || '')),
        state: data.data.state ?? '',
        zipCode: data.data.zipCode
      });
    }
  };

  useEffect(() => {
    getMyAccount();
  }, []);

  useEffect(handleUpdatedData, [data]);

  const subheaderClassName =
    'mt-5 text-mBase font-bold text-gray-700 md:mt-6 md:text-xl md:text-primary-700';
  const rowInputsClassName = 'grid md:grid-cols-2 gap-4';

  return (
    <FadeWrapper>
      <SuggestedAddress
        currentAddress={getAddress()}
        isOpen={isOpenSuggestedAddress}
        suggestedAddress={suggestedAddress}
        onClose={() => {
          setSuggestedAddress(undefined);
          toggleSuggestedAddress(false);
        }}
        onConfirm={(mailingAddress) => handleUpdateMyAccount({ ...getValues(), ...mailingAddress })}
      />
      <form
        data-testid="account_info_section"
        role="form"
        onSubmit={handleSubmit(onFormSubmit, onFormError)}
      >
        <div className="mx-auto flex max-w-[500px] flex-col gap-3 py-4 max-md:px-px md:gap-4 md:py-8">
          <div className="flex items-center gap-5 md:self-center">
            <ProfileAvatar
              fileForUpdateAvatar={fileForUpdateAvatar}
              src={user.profileImage}
              onUpdateLogo={setFileForUpdateAvatar}
            />
            <div className="flex flex-col gap-2">
              <p className="text-mLg font-bold text-gray-700 md:text-2xl">
                {user.firstName} {user.lastName}
              </p>
              {!!user.activePlanName && (
                <Common.ColorTag
                  color="primary"
                  text={isTTPatient ? TRIPLE_THERAPY_PLAN_NAME : user.activePlanName}
                />
              )}
            </div>
          </div>
          <h2 className={subheaderClassName}>Personal information</h2>
          <TextInput
            control={control}
            dataTestId="firstname_field"
            invalidErrorMsg="First name is invalid"
            label="Legal first name"
            name="personalInfo.firstName"
            placeholder="First name"
            requiredErrorMsg="First name is required"
          />
          <TextInput
            control={control}
            dataTestId="lastname_field"
            invalidErrorMsg="Last name is invalid"
            label="Legal last name"
            name="personalInfo.lastName"
            placeholder="Last name"
            requiredErrorMsg="Last name is required"
          />
          <TextInput
            control={control}
            dataTestId="nickname_field"
            invalidErrorMsg="Invalid preferred name"
            isRequired={false}
            label="Preferred first name (optional)"
            name="personalInfo.nickName"
            placeholder="Preferred name"
          />
          <BirthInput
            control={control}
            hint={DateFormat.MM_DD_YYYY}
            name="personalInfo.dob"
            preIcon="calendar"
          />
          <div className={rowInputsClassName}>
            <SexAtBirthSelect
              control={control}
              dataTestId="sex_at_birth_in_account"
              label="Sex at birth"
              name="personalInfo.sexAtBirth"
              hideSuccessState
            />
            <GenderSelect control={control} dataTestId="gender_select" name="personalInfo.gender" />
          </div>
          <Common.TextArea
            dataTestId="about_yourself"
            label="About me"
            placeholder="Tell us about yourself..."
            {...AboutMeField}
            errors={formState.errors[AboutMeField.name]}
            rows={3}
          />
          <h2 className={subheaderClassName}>Contact information</h2>
          <div className={rowInputsClassName}>
            <PhoneInput
              className="md:max-w-[242px]"
              control={control}
              dataTestId="phone_in_account"
              label="Phone number"
              name="phone"
            />
            <VerifyEmailInput
              control={control}
              dataTestId="email_in_account"
              error={formState.errors.email?.message}
              label="Email"
              name="email"
              status={user?.pendingEmail ? EmailStatus.PENDING : user.emailStatus}
            />
          </div>
          <h2 className={subheaderClassName}>Address</h2>
          <Address control={control} setValue={setValue} trigger={trigger} />
        </div>
        <div className="sticky inset-x-4 bottom-0 flex flex-wrap justify-center gap-4 rounded-b-2xl border-gray-200 max-md:mb-5 md:border-t md:bg-white md:px-8 md:py-6">
          <Common.Button
            color="blue"
            dataTestId="save_btn"
            disabled={isLoading || (!formState.isDirty && !fileForUpdateAvatar)}
            isLoading={isLoading || isGettingSuggestions}
            fullWidthOnMobile
          >
            Save profile
          </Common.Button>
          <Common.Button
            className="hidden md:block"
            color="white-alt"
            dataTestId="discard_btn"
            disabled={isLoading || (!formState.isDirty && !fileForUpdateAvatar)}
            type="button"
            onClick={handleCancel}
          >
            Cancel
          </Common.Button>
        </div>
      </form>
    </FadeWrapper>
  );
};

export default AccountInfo;
