import { Common } from '@thecvlb/design-system/lib/src';

import tirzepatide from 'assets/images/signUp/tirzepatide.webp';

interface IntroProps {
  onContinue: () => void;
}

const Intro: React.FC<IntroProps> = ({ onContinue }) => {
  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-col items-center gap-2 py-6 text-center">
        <div>
          <img alt="tirzepatide" className="size-[96px] rounded-full" src={tirzepatide} />
        </div>
        <h4 className="text-m2xl font-bold text-primary-700 md:text-2xl">Heads up!</h4>
        <span>
          The FDA recently removed the tirzepatide injection from it's Drug Shortage List, impacting
          the compounded medication options available to patients.
        </span>
        <span>
          <span className="font-bold text-primary">Don't worry!</span> LifeMD is committed to
          providing patients with safe options that preserve continuity of care. Please complete the
          following survey (~ 1 minute) to keep you moving forward on your weight loss journey.
        </span>
      </div>
      <Common.Button
        className="py-6 max-md:mt-auto md:self-center"
        color="blue"
        fullWidthOnMobile
        onClick={onContinue}
      >
        Go to survey
      </Common.Button>
    </div>
  );
};

export default Intro;
