import { createApi } from '@reduxjs/toolkit/query/react';

import { ResponseProps } from 'services/services.types';

import { setCart } from 'store/shop/shopSlice';

import { baseQueryWithReauth } from 'utils/services';

import { ShopOrderDetails } from 'models/shop';

import {
  AddProductToCartReq,
  CheckoutReqProps,
  CheckoutResProps,
  GetCartInfoRes,
  GetProductDetailsRes,
  GetProductsListRes,
  GetShopOrdersRes
} from './shop.types';

export const shopApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    getProducts: build.query<GetProductsListRes, void>({
      keepUnusedDataFor: 0,
      query: () => ({
        url: '/store/products'
      })
    }),
    getSingleProductDetails: build.query<GetProductDetailsRes, string>({
      query: (id) => ({
        url: `/store/products/${id}`
      })
    }),
    addToCart: build.mutation<unknown, AddProductToCartReq>({
      query: ({ productId, ...body }) => ({
        url: `/store/cart/${productId}`,
        method: 'POST',
        body
      })
    }),
    getCart: build.query<GetCartInfoRes, void>({
      query: () => ({
        url: '/store/cart'
      }),
      keepUnusedDataFor: 0,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(setCart(data.data));
        });
      }
    }),
    storeCheckout: build.mutation<CheckoutResProps, CheckoutReqProps>({
      query: (body) => ({
        url: '/store/checkout',
        method: 'POST',
        body
      })
    }),
    deleteFromCart: build.mutation<unknown, Pick<AddProductToCartReq, 'productId'>>({
      query: ({ productId }) => ({
        url: `/store/cart/${productId}`,
        method: 'DELETE'
      })
    }),
    getShopOrders: build.query<GetShopOrdersRes, void>({
      query: () => ({
        url: '/store/orders'
      })
    }),
    getSingleOrderDetails: build.query<ResponseProps<ShopOrderDetails>, string>({
      query: (id) => ({
        url: `/store/orders/${id}`
      })
    })
  }),
  reducerPath: 'shopApi',
  tagTypes: ['shop']
});

export const {
  useGetProductsQuery,
  useGetSingleProductDetailsQuery,
  useLazyGetCartQuery,
  useGetCartQuery,
  useAddToCartMutation,
  useDeleteFromCartMutation,
  useGetSingleOrderDetailsQuery,
  useLazyGetSingleOrderDetailsQuery,
  useGetShopOrdersQuery,
  useStoreCheckoutMutation
} = shopApi;
